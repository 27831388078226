import React,{useState, useEffect, useContext} from 'react';


const ExportA = () => {


    return (
        <div>
            Hello world
        </div>
            );
};


export default ExportA;