export const translations = {
    en: {
        Dashboard: "Dashboard",
        TraceReport: "Trace Report",
        SelectCompany: "Please select company to generate trace report",
        SelectCompanyShort: "Select Company",
        Production: "Production",
        BagsProduced: "Bags Produced",
        Processing: "Processing",
        BagsProcessed: "Bags Processed",
        Blending: "Blending",
        Drums: "Drums",
        Exports: "Exports",
        TotalStockDelivery: "Total Stock Delivery",
        Cassiterite: "Cassiterite",
        Date: "Date",
        DailyTarget: "Daily Target (TONS)",
        DailyActuals: "Daily Actuals (TONS)",
        MonthlyTarget: "Monthly Target (TONS)",
        MTDTarget: "MTD Target (TONS)",
        MTDActuals: "MTD Actuals (TONS)",
        MTDActualsVsTarget: "MTD Actuals vs Target (%)",
        MineralsPerformanceOverview: "Minerals Performance Overview",
        InStockCountryBalance: "In-Stock Country Balance",
        OverallBalanceAsOf: "Overall Balance as of",
        WithRMR: "With RMR (TONS)",
        WithMinexx: "With Minexx (TONS)",
        PendingShipment: "Pending Shipment (TONS)",
        Shipped: "Shipped (TONS)",
        WithBuyer: "With Buyer (SOLD)",
        TotalPurchase: "Total Purchase",
        ThisWeek: "This Week",
        ThisMonth: "This Month",
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
        Report:"Report",
        //Production Tab
        ProductionWeight: "Production Weight (Kg)",
        BusinessLocation: "Business Location",
        NameOfRMBRepresentative: "Name of RMB Representative",
        TraceabilityAgent: "Traceability Agent",
        NameOfOperatorRepresentative: "Name of Operator Representative",
        NumberOfBags: "Number of Bags",
        TotalWeight: "Total Weight (Kg)",
        Note: "Note",
        //bag produced
        BagsProduced: "Bags Produced",
        TagNumber: "Tag Number",
        Weight: "Weight (Kg)",
        TunnelPitNumberOrName: "Tunnel/Pit Number or Name",
        ProductionMiningDate: "Production/Mining Date",
        MinerName: "Miner Name",
        TransporterName: "Transporter Name",
        RMBRepresentativeAtMineSite: "RMB Representative at Mine Site",
        SecurityOfficerName: "Security Officer Name",
        EstimatedConcentratePercentage: "Estimated concentrate %",
        ColorOfTheBagDrumPackage: "Color of The Bag/Drum Package",
        TransportMode: "Transport Mode",
        TransportItinerary: "Transport Itinerary",
        Time: "Time",
        ProductionID: "Production ID",
        //proccessing tab
        Processing: "Processing",
        Date: "Date",
        BusinessLocation: "Business Location",
        RMBRepresentative: "RMB Representative",
        TraceabilityAgent: "Traceability Agent",
        OperatorRepresentative: "Operator Representative",
        MineralType: "Mineral Type",
        NumberOfInputBags: "Number of Input Bags",
        TotalInputWeight: "Total Input Weight (kg)",
        NumberOfOutputBags: "Number of Output Bags",
        TotalOutputWeight: "Total Output Weight(kg)",
        TagNumber: "Tag Number",
        TaggingDateTime: "Tagging Date and Time",
        Grade: "Grade (%)",
        ProcessingWeight: "Processing Weight (kg)",
        Note: "Note",
        NameOfMineSupplier: "Name of mine supplier",
        PresenceOfASI: "Presence of Alex Stuart International (ASI)",
        Laboratory: "Laboratory",
        Certificate: "Certificate",
        PricingUSD: "Pricing (USD)",
        LME: "LME",
        TC: "TC",
        PricePerTaPercentage: "Price per Ta (%)",
        UnitPrice: "Unit Price",
        TotalPrice: "Total Price",
        PaymentMethod: "Payment Method",
        SecurityOfficerName: "Security Officer Name",
        LotNumber: "Lot Number",
        //bag Proccesed
        BagsProcessed: "Bags Processed",
        TagNumber: "Tag Number",
        Weight: "Weight (Kg)",
        ProcessingID: "Processing ID",
        ProductionMiningDate: "Production/Mining Date",
        RMBRepresentativeAtMineSite: "RMB Representative at Mine Site",
        SecurityOfficerName: "Security Officer Name",
        Time: "Time",
        StorageContainer: "Storage Container",
        ColorOfThePackageContainer: "Color of the Package/Container",
        MineralType: "Mineral Type",
        Grade: "Grade (%)",
        //Blending tab
        Blending: "Blending",
        ID: "ID",
        BlendingDate: "Blending Date",
        LotNumberProcessed: "Lot Number (Processed)",
        ProcessedBags: "Processed Bags",
        ProcessedBags2: "Processed Bags2",
        ConcessionName: "Concession Name",
        BusinessLocation: "Business Location",
        NameOfRMBRepresentative: "Name of RMB Representative",
        TraceabilityAgent: "Traceability Agent",
        NameOfOperatorRepresentative: "Name of Operator Representative",
        NumberOfInputTags: "Number of Input Tags",
        TypeOfOutputMaterial: "Type of Output Material",
        TotalInputWeightKg: "Total Input Weight(kg)",
        NumberOfOutputBlendedBatches: "Number of Output blended batches",
        TotalOutputWeightKg: "Total Output Weight(kg)",
        TagNumber: "Tag Number",
        TaggingDateAndTime: "Tagging Date and Time",
        MineralType: "Mineral type",
        TinGrade: "Tin Grade (%)",
        AlexStewartCertificateOfAssay: "Alex Stewart Certificate of Assay",
        AlexStewartPackingReport: "Alex Stewart Packing report",
        TantalumGrade: "Tantalum Grade (%)",
        CompanyName: "Company Name",
        ComptoirName: "Comptoir Name",
        Note: "Note",
        Picture: "Picture",
        BlendingWeightKg: "Blending Weight (kg)",
        SumBags: "SumBags",
        ITSCIProcessingFormNumber: "ITSCI Processing Form Number",
        PresenceOfASI: "Presence of Alex Stuart International (ASI)",
        ShipmentNumber: "Shipment number",
        NameOfASIInspector: "Name of ASI inspector",
        SecurityOfficerName: "Security officer Name",
        LotNumber: "Lot number",
        Picture: "Picture",
        //drum tab
        Drums: "Drums",
        DrumNumber: "Drum Number",
        GrossWeight: "Gross Weight",
        NetWeight: "Net Weight",
        ITSCITagNumber: "ITSCI Tag Number",
        DrumBagColor: "Drum/Bag Color",
        Grade: "Grade (%)",
        BlendingID: "Blending ID",
        ASITagNumber: "ASI Tag Number",
        //export tab
        Exports: "Exports",
        Date: "Date",
        MineralType: "Mineral Type",
        Grade: "Grade",
        NetWeightKg: "Net Weight (kg)",
        GrossWeightKg: "Gross Weight (kg)",
        ExportationID: "Exportation ID",
        RMBRepresentative: "RMB Representative",
        ExporterRepresentative: "Exporter Representative",
        TraceabilityAgent: "Traceability Agent",
        Destination: "Destination",
        Itinerary: "Itinerary",
        ShipmentNumber: "Shipment Number",
        ExportCertificateNumber: "Export Certificate Number",
        RRACertificateNumber: "RRA certificate Number",
        ExportValueUSD: "Export value (USD)",
        Transporter: "Transporter",
        IDNumberOfDriver: "ID Number of the driver",
        TruckPlateNumberFront: "Truck Plate Number - Front",
        TruckPlateNumberBack: "Truck Plate Number - Back",
        NumberOfTags: "Number of tags",
        TotalGrossWeightKg: "Total Gross Weight (kg)",
        TotalNetWeightKg: "Total Net Weight (kg)",
        Attachments: "Attachments",
        Purchase:"Purchase",
        NoPurchaseRecords:"Company does not have any purchase records to report on.",
        NoProduction: "The selected company does not have any production to show.",
        Showing: "Showing",
        Percentage: "TONS/Percentage",
        To: "to",
        Of: "of",
        Entries: "entries",
        Previous: "previous",
        Next: "next",
        NoSelected: "The selected company does not have any produced bags to show.",
        NoExports: "The selected company does not have any exports to show.",
        NoDrums: "The selected company does not have any drums to show.",
        NoProcessedBags: "The selected company does not have any processed bags to show.",
        NoProcessing: "The selected company does not have any processing to show.",
        NoBlendingRecords: "Company does not have any blending records to report on.",
        TotalAmountPaid: "Total Amount Paid ($)",
        //blennding
        "ID": "ID",
        "Blending Date": "Blending Date",
        "Lot Number (Processed)": "Lot Number (Processed)",
        "Processed Bags": "Processed Bags",
        "Processed Bags2": "Processed Bags2",
        "Concession Name": "Concession Name",
        "Business Location": "Business Location",
        "Name Of RMB Representative": "Name Of RMB Representative",
        "Traceability Agent": "Traceability Agent",
        "Name Of Operator Representative": "Name Of Operator Representative",
        "Number Of Input Tags": "Number Of Input Tags",
        "Type Of Output Material": "Type Of Output Material",
        "Total Input Weight(Kg)": "Total Input Weight(Kg)",
        "Number Of Output Blended Batches": "Number Of Output Blended Batches",
        "Total Output Weight(Kg)": "Total Output Weight(Kg)",
        "Tag Number": "Tag Number",
        "Tagging Date And Time": "Tagging Date And Time",
        "Mineral Type": "Mineral Type",
        "Tin Grade (%)": "Tin Grade (%)",
        "Alex Stewart Certificate Of Assay": "Alex Stewart Certificate Of Assay",
        "Alex Stewart Packing Report": "Alex Stewart Packing Report",
        "Tantalum Grade (%)": "Tantalum Grade (%)",
        "Company Name": "Company Name",
        "Comptoir Name": "Comptoir Name",
        "Note D'information": "Information Note",
        "Photo": "Photo",
        "Blending Weight (Kg)": "Blending Weight (Kg)",
        "SumBags": "Sum of Bags",
        "ITSCI Processing Form Number": "ITSCI Processing Form Number",
        "Presence Of Alex Stuart International (ASI)": "Presence Of Alex Stuart International (ASI)",
        "Shipment Number": "Shipment Number",
        "Name Of ASI Inspector": "Name Of ASI Inspector",
        "Security Officer Name": "Security Officer Name",
        "Lot Number Photo": "Lot Number Photo",
        //Production
        "Transaction Unique ID": "Transaction Unique ID",
        "Weight (g)": "Weight (g)",
        "Timestamp": "Timestamp",
        "Seller": "Seller",
        "Buyer Name": "Buyer Name",
        "Buyer Phone Number": "Buyer Phone Number",
        "Buyer Type": "Buyer Type",
        "Carat": "Carat",
        "Total Price": "Total Price",
        "Total Price (CFA)": "Total Price (CFA)",
        "Quality Constant": "Quality Constant",
        "Price for 10 blades": "Price for 10 blades",
        "Price per g": "Price per g",
        "Image": "Image",
        "Date": "Date",
        "Notes": "Notes",
        "Image 2": "Image 2",
        "Miner ID": "Miner ID",
        "number of stones (For Diamond only)": "Number of Stones (For Diamond only)",
        "colored stone (For Diamond only)": "Colored Stone (For Diamond only)",
        "Mineral": "Mineral",
        "Receipt": "Receipt",
        "Last Modification": "Last Modification",
        "ThisUser": "This User",
        "role": "Role",
        //Purchase
        "Transaction Unique ID": "Transaction Unique ID",
        "Mineral": "Mineral",
        "Weight (g)": "Weight (g)",
        "Timestamp": "Timestamp",
        "Seller Type": "Seller Type",
        "Seller Name": "Seller Name",
        "Seller Phone": "Seller Phone",
        "Mine Name": "Mine Name",
        "Mine Village": "Mine Village",
        "Buyer": "Buyer",
        "Carat": "Carat",
        "Total Price": "Total Price",
        "Total Price (CFA)": "Total Price (CFA)",
        "Price for 10 blades": "Price for 10 blades",
        "Price per g": "Price per g",
        "Quality Constant": "Quality Constant",
        "Image": "Image",
        "Date": "Date",
        "Notes": "Notes",
        "Image 2": "Image 2",
        "clarity (For Diamond only)": "Clarity (For Diamond only)",
        "shape (For Diamond only)": "Shape (For Diamond only)",
        "Fluorescence (For Diamond only)": "Fluorescence (For Diamond only)",
        "Colour": "Colour",
        "Receipt": "Receipt",
        "Miner ID": "Miner ID",
        "Last Modification": "Last Modification"
        
    },
    fr: {
        Dashboard: "Tableau de bord",
        Report:"Rapport",
        TraceReport: "Rapport de traçage",
        SelectCompany: "Veuillez sélectionner une entreprise pour générer un rapport de traçabilité",
        SelectCompanyShort: "Sélectionnez une entreprise",
        Production: "Production",
        BagsProduced: "Sacs produits",
        Processing: "Traitement",
        BagsProcessed: "Sacs traités",
        Blending: "Mélange",
        Drums: "Tambours",
        Exports: "Exportations",
        TotalStockDelivery: "Livraison totale du stock",
        Cassiterite: "Cassitérite",
        Date: "Date d'entrée en vigueur",
        DailyTarget: "Objectif journalier (Tonnes)",
        DailyActuals: "Résultats quotidiens (Tonnes)",
        MonthlyTarget: "Objectif mensuel (Tonnes)",
        MTDTarget: "Objectif MTD (Tonnes)",
        MTDActuals: "Réalisations MTD (Tonnes)",
        MTDActualsVsTarget: "Réalisations MTD par rapport à l'objectif (%)",
        MineralsPerformanceOverview: "Aperçu des performances des minéraux",
        InStockCountryBalance: "En stock Pays Balance",
        OverallBalanceAsOf: "Solde global au",
        WithRMR: "Avec RMR (Tonnes)",
        WithMinexx: "Avec Minexx (TONS)",
        PendingShipment: "En attente d'expédition (Tonnes)",
        Shipped: "Expédié (Tonnes)",
        WithBuyer: "Avec l'acheteur (VENDUES)",
        Percentage: "TONNES/Pourcentage",
        TotalPurchase: "Achat Total",
        Purchase:"Achat",
        NoPurchaseRecords:"La société n'a aucun enregistrement d'achat à signaler.",
        TotalAmountPaid: "Montant total payé ($)",
        ThisWeek: "Cette semaine",
        ThisMonth: "Ce mois-ci",
        January: "Janvier",
        February: "Février",
        March: "mars",
        April: "avril",
        May: "Mai",
        June: "juin",
        July: "juillet",
        August: "Août",
        September: "septembre",
        October: "octobre",
        November: "novembre",
        December: "Décembre",
        //production Tab
        ProductionWeight: "Poids de production (Kg)",
        BusinessLocation: "Lieu d'activité",
        NameOfRMBRepresentative: "Nom du représentant de RMB",
        TraceabilityAgent: "Agent de traçabilité",
        NameOfOperatorRepresentative: "Nom du représentant de l'opérateur",
        NumberOfBags: "Nombre de sacs",
        TotalWeight: "Poids total (Kg)",
        Note: "Remarque",
        //Bag produced
        BagsProduced: "Sacs produits",
        TagNumber: "Numéro d'étiquette",
        Weight: "Poids (Kg)",
        TunnelPitNumberOrName: "Numéro ou nom du tunnel/fosse",
        ProductionMiningDate: "Date de production/exploitation",
        MinerName: "Nom du mineur",
        TransporterName: "Nom du transporteur",
        RMBRepresentativeAtMineSite: "Représentant de la RMB sur le site de la mine",
        SecurityOfficerName: "Nom de l'agent de sécurité",
        EstimatedConcentratePercentage: "Estimation du pourcentage de concentré",
        ColorOfTheBagDrumPackage: "Couleur de l'emballage du sac/tambour",
        TransportMode: "Mode de transport",
        TransportItinerary: "Itinéraire de transport",
        Time: "Durée du transport",
        ProductionID: "ID de production",
        //proccessing
        Processing: "Traitement",
        Date: "Date d'entrée en vigueur",
        BusinessLocation: "Lieu d'activité",
        RMBRepresentative: "Représentant RMB",
        TraceabilityAgent: "Agent de traçabilité",
        OperatorRepresentative: "Représentant de l'opérateur",
        MineralType: "Type de minerai",
        NumberOfInputBags: "Nombre de sacs d'entrée",
        TotalInputWeight: "Poids total des intrants (kg)",
        NumberOfOutputBags: "Nombre de sacs de sortie",
        TotalOutputWeight: "Poids total en sortie (kg)",
        TagNumber: "Numéro de l'étiquette",
        TaggingDateTime: "Date et heure de marquage",
        Grade: "Qualité (%)",
        ProcessingWeight: "Poids de transformation (kg)",
        Note: "Remarque",
        NameOfMineSupplier: "Nom du fournisseur de la mine",
        PresenceOfASI: "Présence d'Alex Stuart International (ASI)",
        Laboratory: "Laboratoire",
        Certificate: "Certificat",
        PricingUSD: "Prix (USD)",
        LME: "LME",
        TC: "TC",
        PricePerTaPercentage: "Prix par Ta (%)",
        UnitPrice: "Prix unitaire",
        TotalPrice: "Prix total",
        PaymentMethod: "Mode de paiement",
        SecurityOfficerName: "Nom de l'agent de sécurité",
        LotNumber: "Numéro de lot",
        //Bag Proccessed
        BagsProcessed: "Sacs produits",
        TagNumber: "Numéro d'étiquette",
        Weight: "Poids (Kg)",
        ProcessingID: "ID de traitement",
        ProductionMiningDate: "Date de production/exploitation",
        RMBRepresentativeAtMineSite: "Représentant de la RMB sur le site de la mine",
        SecurityOfficerName: "Nom de l'agent de sécurité",
        Time: "Heure",
        StorageContainer: "Conteneur de stockage",
        ColorOfThePackageContainer: "Couleur de l'emballage/du conteneur",
        MineralType: "Type de minéral",
        Grade: "Teneur (%)",
        //Blending tab
        Blending: "Mélange",
        ID: "ID",
        BlendingDate: "Date du mélange",
        LotNumberProcessed: "Numéro de lot (traité)",
        ProcessedBags: "Sacs traités",
        ProcessedBags2: "Sacs traités2",
        ConcessionName: "Nom de la concession",
        BusinessLocation: "Emplacement de l'entreprise",
        NameOfRMBRepresentative: "Nom du représentant de RMB",
        TraceabilityAgent: "Agent de traçabilité",
        NameOfOperatorRepresentative: "Nom du représentant de l'opérateur",
        NumberOfInputTags: "Nombre d'étiquettes d'entrée",
        TypeOfOutputMaterial: "Type de matériau de sortie",
        TotalInputWeightKg: "Poids total de l'intrant (kg)",
        NumberOfOutputBlendedBatches: "Nombre de lots mélangés en sortie",
        TotalOutputWeightKg: "Poids total en sortie (kg)",
        TagNumber: "Numéro de l'étiquette",
        TaggingDateAndTime: "Date et heure de marquage",
        MineralType: "Type de minéral",
        TinGrade: "Teneur en étain (%)",
        AlexStewartCertificateOfAssay: "Certificat d'analyse Alex Stewart",
        AlexStewartPackingReport: "Rapport d'emballage Alex Stewart",
        TantalumGrade: "Teneur en tantale (%)",
        CompanyName: "Nom de la société",
        ComptoirName: "Nom du comptoir",
        Note: "Note d'information",
        Picture: "Image",
        BlendingWeightKg: "Poids du mélange (kg)",
        SumBags: "SumBags",
        ITSCIProcessingFormNumber: "Numéro du formulaire de traitement ITSCI",
        PresenceOfASI: "Présence d'Alex Stuart International (ASI)",
        ShipmentNumber: "Numéro d'expédition",
        NameOfASIInspector: "Nom de l'inspecteur d'ASI",
        SecurityOfficerName: "Nom de l'agent de sécurité",
        LotNumber: "Numéro de lot",
        Picture: "Photo",
        //drum Tab
        Drums: "Tambour",
        DrumNumber: "Numéro de tambour",
        GrossWeight: "Poids brut",
        NetWeight: "Poids net",
        ITSCITagNumber: "Numéro d'étiquette ITSCI",
        DrumBagColor: "Couleur du fût/sac",
        Grade: "Qualité (%)",
        BlendingID: "ID du mélange",
        ASITagNumber: "Numéro d'étiquette ASI",
        //export tab
        Exports: "Exportations",
        Date: "Date",
        MineralType: "Type de minéral",
        Grade: "Qualité",
        NetWeightKg: "Poids net (kg)",
        GrossWeightKg: "Poids brut (kg)",
        ExportationID: "ID d'exportation",
        RMBRepresentative: "Représentant RMB",
        ExporterRepresentative: "Représentant de l'exportateur",
        TraceabilityAgent: "Agent de traçabilité",
        Destination: "Destination",
        Itinerary: "Itinéraire",
        ShipmentNumber: "Numéro d'expédition",
        ExportCertificateNumber: "Numéro du certificat d'exportation",
        RRACertificateNumber: "Numéro de certificat RRA",
        ExportValueUSD: "Valeur à l'exportation (USD)",
        Transporter: "Transporteur",
        IDNumberOfDriver: "Numéro d'identification du chauffeur",
        TruckPlateNumberFront: "Numéro de plaque du camion - avant",
        TruckPlateNumberBack: "Numéro de la plaque du camion - arrière",
        NumberOfTags: "Nombre d'étiquettes",
        TotalGrossWeightKg: "Poids total brut (kg)",
        TotalNetWeightKg: "Poids total net (kg)",
        Attachments: "Pièces jointes",
        NoProduction: "L'entreprise sélectionnée n'a aucune production à afficher.",
        Showing: "Affichage de",
        To: "à",
        Of: "de",
        Entries: "entrées",
        Previous: "Précédent",
        Next: "suivant",
        NoSelected: "L'entreprise sélectionnée n'a aucun sac produit à afficher.",
        NoExports: "L'entreprise sélectionnée n'a aucune exportation à afficher.",
        NoDrums: "L'entreprise sélectionnée n'a aucun tambour à afficher.",
        NoProcessedBags: "L'entreprise sélectionnée n'a aucun sac traité à afficher.",
        NoProcessing: "L'entreprise sélectionnée n'a aucun traitement à afficher.",
        NoBlendingRecords: "L'entreprise n'a aucun enregistrement de mélange à signaler.",
        //blending
        "ID": "ID",
        "Blending Date": "Date de mélange",
        "Lot Number (Processed)": "Numéro de lot (traité)",
        "Processed Bags": "Sacs traités",
        "Processed Bags2": "Sacs traités 2",
        "Concession Name": "Nom de la concession",
        "Business Location": "Emplacement de l'entreprise",
        "Name Of RMB Representative": "Nom du représentant RMB",
        "Traceability Agent": "Agent de traçabilité",
        "Name Of Operator Representative": "Nom du représentant de l'opérateur",
        "Number Of Input Tags": "Nombre d'étiquettes d'entrée",
        "Type Of Output Material": "Type de matériau de sortie",
        "Total Input Weight(Kg)": "Poids total d'entrée (Kg)",
        "Number Of Output Blended Batches": "Nombre de lots mélangés en sortie",
        "Total Output Weight(Kg)": "Poids total de sortie (Kg)",
        "Tag Number": "Numéro d'étiquette",
        "Tagging Date And Time": "Date et heure d'étiquetage",
        "Mineral Type": "Type de minéral",
        "Tin Grade (%)": "Teneur en étain (%)",
        "Alex Stewart Certificate Of Assay": "Certificat d'analyse Alex Stewart",
        "Alex Stewart Packing Report": "Rapport d'emballage Alex Stewart",
        "Tantalum Grade (%)": "Teneur en tantale (%)",
        "Company Name": "Nom de l'entreprise",
        "Comptoir Name": "Nom du comptoir",
        "Note D'information": "Note d'information",
        "Photo": "Photo",
        "Blending Weight (Kg)": "Poids de mélange (Kg)",
        "SumBags": "Somme des sacs",
        "ITSCI Processing Form Number": "Numéro de formulaire de traitement ITSCI",
        "Presence Of Alex Stuart International (ASI)": "Présence d'Alex Stuart International (ASI)",
        "Shipment Number": "Numéro d'expédition",
        "Name Of ASI Inspector": "Nom de l'inspecteur ASI",
        "Security Officer Name": "Nom de l'agent de sécurité",
        "Lot Number Photo": "Photo du numéro de lot",
        //production
        "Transaction Unique ID": "ID unique de transaction",
        "Weight (g)": "Poids (g)",
        "Timestamp": "Horodatage",
        "Seller": "Vendeur",
        "Buyer Name": "Nom de l'acheteur",
        "Buyer Phone Number": "Numéro de téléphone de l'acheteur",
        "Buyer Type": "Type d'acheteur",
        "Carat": "Carat",
        "Total Price": "Prix total",
        "Total Price (CFA)": "Prix total (CFA)",
        "Quality Constant": "Constante de qualité",
        "Price for 10 blades": "Prix pour 10 lames",
        "Price per g": "Prix par g",
        "Image": "Image",
        "Date": "Date",
        "Notes": "Notes",
        "Image 2": "Image 2",
        "Miner ID": "ID du mineur",
        "number of stones (For Diamond only)": "Nombre de pierres (Pour diamant uniquement)",
        "colored stone (For Diamond only)": "Pierre colorée (Pour diamant uniquement)",
        "Mineral": "Minéral",
        "Receipt": "Reçu",
        "Last Modification": "Dernière modification",
        "ThisUser": "Cet utilisateur",
        "role": "Rôle",
        //For Purchase
        "Transaction Unique ID": "ID unique de transaction",
        "Mineral": "Minéral",
        "Weight (g)": "Poids (g)",
        "Timestamp": "Horodatage",
        "Seller Type": "Type de vendeur",
        "Seller Name": "Nom du vendeur",
        "Seller Phone": "Téléphone du vendeur",
        "Mine Name": "Nom de la mine",
        "Mine Village": "Village minier",
        "Buyer": "Acheteur",
        "Carat": "Carat",
        "Total Price": "Prix total",
        "Total Price (CFA)": "Prix total (CFA)",
        "Price for 10 blades": "Prix pour 10 lames",
        "Price per g": "Prix par g",
        "Quality Constant": "Constante de qualité",
        "Image": "Image",
        "Date": "Date",
        "Notes": "Notes",
        "Image 2": "Image 2",
        "clarity (For Diamond only)": "Clarté (Pour diamant uniquement)",
        "shape (For Diamond only)": "Forme (Pour diamant uniquement)",
        "Fluorescence (For Diamond only)": "Fluorescence (Pour diamant uniquement)",
        "Colour": "Couleur",
        "Receipt": "Reçu",
        "Miner ID": "ID du mineur",
        "Last Modification": "Dernière modification"
    }
};