export const translations = {
    en: {
      Dashboard: "Dashboard",
      "General Information": "General Information",
      "Legitimacy": "Legitimacy",
      "Human & Workers Rights": "Human & Workers Rights",
      "Societal Welfare/Security": "Societal Welfare/Security",
      "Company Governance": "Company Governance",
      "Chain of Custody/Traceability/Tracking": "Chain of Custody/Traceability/Tracking",
      "Environment": "Environment",
      "Community Impact": "Community Impact",
      "Name of Mining Entity": "Name of Mining Entity",
      "Tax Identification Number": "Tax Identification Number",
      "Type of entity": "Type of entity",
      "Mine/Concession Name": "Mine/Concession Name",
      "Mine Address": "Mine Address",
      "Mine License Number": "Mine License Number",
      "Number of Partners": "Number of Partners",
      "Number of Workers": "Number of Workers",
      "Number of Women Workers": "Number of Women Workers",
      "Number of permanent staff and workers": "Number of permanent staff and workers",
      "Type of workers contract": "Type of workers contract",
      "Length of contract": "Length of contract",
      "Name of Legal Representative": "Name of Legal Representative",
      "Nationality": "Nationality",
      "Date of birth": "Date of birth",
      "His/Her Address": "His/Her Address",
      "Contact Person: Name": "Contact Person: Name",
      "Title": "Title",
      "Email": "Email",
      "Cell Phone Number": "Cell Phone Number",
      Mines: "Mines",
      AssessmentDetails: "Assessment Details",
      "Affiliation of the Entity to Polical Party, Federation or Union": "Affiliation of the Entity to Political Party, Federation or Union",
      "Description of your Entity": "Description of your Entity",
      "Assessment Period (start date)": "Assessment Period (start date)",
      "Assessment Period (end date)": "Assessment Period (end date)",
      "Assessment Type": "Assessment Type",
      "Concession Area (Ha/acres)": "Concession Area (Ha/acres)",
      "Mine unique number": "Mine unique number",
      "Start year of the production": "Start year of the production",
      "Type of minerals/concentrate": "Type of minerals/concentrate",
      "Type of operation": "Type of operation",
      "Number and names of subsites (if applicable)": "Number and names of subsites (if applicable)",
      "Mining Method": "Mining Method",
      "Number of large open pit": "Number of large open pit",
      "Number of small open pit": "Number of small open pit",
      "Number of tunnel": "Number of tunnel",
      "Number of abandoned pit and or tunnel": "Number of abandoned pit and or tunnel",
      "Mining equipment (list them)": "Mining equipment (list them)",
      "Washing methods": "Washing methods",
      "Daily produced volume (Kg/ton)": "Daily produced volume (Kg/ton)",
      "Monthly volume (Kg/ton)": "Monthly volume (Kg/ton)",
      "Production capacity/hour of the mine's washing plant (if relevent)": "Production capacity/hour of the mine's washing plant (if relevant)",
      "Image folder": "Image folder",
      "Assessment Firm": "Assessment Firm",
      "Lead Assessor Name": "Lead Assessor Name",
      "Lead Assessor Email": "Lead Assessor Email",
      "Lead Assessor Phone": "Lead Assessor Phone",
      "Team Assessor(s) Name(s)": "Team Assessor(s) Name(s)",
      "Overview of the Assessment": "Overview of the Assessment",
      "Staff/Representatives present at the meeting": "Staff/Representatives present at the meeting",
      "Documents review (list and names of reviewed documents)": "Documents review (list and names of reviewed documents)",
      "Limitation of the Assessment": "Limitation of the Assessment",
      "HUMAN AND WORKERS RIGHTS": "HUMAN AND WORKERS RIGHTS",
      "SOCIETAL WELFARE / SECURITY": "SOCIETAL WELFARE / SECURITY",
      "CHAIN OF CUSTODY/Traceability/Tracking": "CHAIN OF CUSTODY/Traceability/Tracking",
      "COMMUNITY IMPACT (Beyond CSR: Minexx Category)": "COMMUNITY IMPACT (Beyond CSR: Minexx Category)",
      "Declaration Signatory Name": "Declaration Signatory Name",
      "Job Title": "Job Title",
      "ID Number": "ID Number",
      "Declaration Date": "Declaration Date",
      "Note": "Note",
      // Add all other keys from the document here
    },
    fr: {
      Dashboard: "Tableau de bord",
      "General Information": "Informations générales",
      "Legitimacy": "Légitimité",
      "Human & Workers Rights": "Droits humains et des travailleurs",
      "Societal Welfare/Security": "Bien-être social/Sécurité",
      "Company Governance": "Gouvernance d'entreprise",
      "Chain of Custody/Traceability/Tracking": "Chaîne de traçabilité/Suivi",
      "Environment": "Environnement",
      "Community Impact": "Impact communautaire",
      "Name of Mining Entity": "Nom de l'entité minière",
      "Tax Identification Number": "Numéro d'identification fiscale",
      "Type of entity": "Type d'entité",
      "Mine/Concession Name": "Nom de la mine/concession",
      "Mine Address": "Adresse de la mine",
      "Mine License Number": "Numéro de licence de la mine",
      "Number of Partners": "Nombre de partenaires",
      "Number of Workers": "Nombre de travailleurs",
      "Number of Women Workers": "Nombre de travailleuses",
      "Number of permanent staff and workers": "Nombre de personnel permanent et de travailleurs",
      "Type of workers contract": "Type de contrat des travailleurs",
      "Length of contract": "Durée du contrat",
      "Name of Legal Representative": "Nom du représentant légal",
      "Nationality": "Nationalité",
      "Date of birth": "Date de naissance",
      "His/Her Address": "Son adresse",
      "Contact Person: Name": "Personne de contact : Nom",
      "Title": "Titre",
      "Email": "Email",
      "Cell Phone Number": "Numéro de téléphone portable",
      AssessmentDetails: "Détails de l'évaluation",
      Mines: "Mines",
      "Affiliation of the Entity to Polical Party, Federation or Union": "Affiliation of the Entity to Political Party, Federation or Union",
      "Description of your Entity": "Description of your Entity",
      "Assessment Period (start date)": "Assessment Period (start date)",
      "Assessment Period (end date)": "Assessment Period (end date)",
      "Assessment Type": "Assessment Type",
      "Concession Area (Ha/acres)": "Concession Area (Ha/acres)",
      "Mine unique number": "Mine unique number",
      "Start year of the production": "Start year of the production",
      "Type of minerals/concentrate": "Type of minerals/concentrate",
      "Type of operation": "Type of operation",
      "Number and names of subsites (if applicable)": "Number and names of subsites (if applicable)",
      "Mining Method": "Mining Method",
      "Number of large open pit": "Number of large open pit",
      "Number of small open pit": "Number of small open pit",
      "Number of tunnel": "Number of tunnel",
      "Number of abandoned pit and or tunnel": "Number of abandoned pit and or tunnel",
      "Mining equipment (list them)": "Mining equipment (list them)",
      "Washing methods": "Washing methods",
      "Daily produced volume (Kg/ton)": "Daily produced volume (Kg/ton)",
      "Monthly volume (Kg/ton)": "Monthly volume (Kg/ton)",
      "Production capacity/hour of the mine's washing plant (if relevent)": "Production capacity/hour of the mine's washing plant (if relevant)",
      "Image folder": "Image folder",
      "Assessment Firm": "Assessment Firm",
      "Lead Assessor Name": "Lead Assessor Name",
      "Lead Assessor Email": "Lead Assessor Email",
      "Lead Assessor Phone": "Lead Assessor Phone",
      "Team Assessor(s) Name(s)": "Team Assessor(s) Name(s)",
      "Overview of the Assessment": "Overview of the Assessment",
      "Staff/Representatives present at the meeting": "Staff/Representatives present at the meeting",
      "Documents review (list and names of reviewed documents)": "Documents review (list and names of reviewed documents)",
      "Limitation of the Assessment": "Limitation of the Assessment",
      "HUMAN AND WORKERS RIGHTS": "DROITS HUMAINS ET DES TRAVAILLEURS",
      "SOCIETAL WELFARE / SECURITY": "BIEN-ÊTRE SOCIAL / SÉCURITÉ",
      "CHAIN OF CUSTODY/Traceability/Tracking": "CHAÎNE DE TRAÇABILITÉ/Suivi",
      "COMMUNITY IMPACT (Beyond CSR: Minexx Category)": "IMPACT COMMUNAUTAIRE (Au-delà de la RSE : Catégorie Minexx)",
      "Declaration Signatory Name": "Declaration Signatory Name",
      "Job Title": "Job Title",
      "ID Number": "ID Number",
      "Declaration Date": "Declaration Date",
      "Note": "Note",
      // Add all other keys from the document here, keeping English as fallback
    }
  };